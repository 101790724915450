/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create charts
// import { Line } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  // Card,
  // CardHeader,
  // CardBody,
  // CardFooter,
  // CardTitle,
  // ListGroupItem,
  // ListGroup,
  // Container,
  Row,
  Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";

// import bigChartData from "variables/charts.js";
import "../../assets/css/custom.css";

class LandingPage extends React.Component {

 

  componentDidMount() {
    document.body.classList.toggle("landing-page");
   
     
  }
  componentWillUnmount() {
    document.body.classList.toggle("landing-page");
  }

 
  render() {
    const mystyle = {
      filter: 'drop-shadow(5px 5px 119px #e14eca)'
    };
    return (
      <>
        <IndexNavbar />
        <div className="wrapper">
          <div className="page-header">
            
            
            
           
            <div className="content-center">
              <Row className="row-grid justify-content-between align-items-center text-left">
                <Col lg="6" md="6">
                  <h1 className="text-white">
                    404 Page not found! <br /><br /></h1>
                    <h4 className="text-white">Oh I’m sorry, did I break your concentration?</h4>
                  
                 
                  <div className="btn-wrapper mb-3">
                    {/* <p className="category text-success d-inline">
                      Take me back to home
                    </p> */}
                    <Button
                      className="btn-link"
                      color="success"
                      href="/"
                      // onClick={e => e.preventDefault()}
                      size="sm"
                    ><p className="category text-success d-inline">
                    Please, continue back to home </p> 
                      <i className="tim-icons icon-compass-05" />
                      {/* <i className="fa fa-home d-lg-none d-xl-none" /> */}
                      
                    </Button>
                  </div>
                  
                </Col>
                <Col >
                  <img
                    alt="Confused Travolta 404"
                    style={mystyle}
                    src={require("assets/img/fogg-146.png")}
                  />
                </Col>
              </Row>
            </div>
          </div>
          
          
          <Footer />
        </div>
      </>
    );
  }
}

export default LandingPage;
