/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// import Spinner from '../examples/Spinner';
// import BounceLoader  from "react-spinners/FadeLoader";
import PageHeader from "components/PageHeader/PageHeader";
import LoadingOverlay from 'react-loading-overlay';
import _ from 'lodash'

import {
  Card,
  CardBody,
  ListGroupItem,
  ListGroup,
  Container,
  Row,
  Col,Progress
  } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";
import CustomPagination from "components/Pagination.js";
import Responsive from "components/Responsive";
import '../../assets/css/loader-overlay.css';





class LandingPage extends React.Component {
  state = {
    contacts: [],
    loading: false,
    currentPage: 1,
    postsPerPage: 39,
    scrollPostion: 0,
    errorMessage: ''
  };

  listenToScrollEvent = () => {
    document.addEventListener("scroll", () => {
      requestAnimationFrame(() => {
        this.calculateScrollDistance();
      });
    });
  }

  calculateScrollDistance = () => {
    const scrollTop = window.pageYOffset; // how much the user has scrolled by
    const winHeight = window.innerHeight;
    const docHeight = this.getDocHeight();

    const totalDocScrollLength = docHeight - winHeight;
    const scrollPostion = Math.floor(scrollTop / totalDocScrollLength * 100)

    this.setState({
      scrollPostion,
    });
  }

  getDocHeight = () => {
    return Math.max(
      document.body.scrollHeight, document.documentElement.scrollHeight,
      document.body.offsetHeight, document.documentElement.offsetHeight,
      document.body.clientHeight, document.documentElement.clientHeight
    );
  }

  componentDidUpdate (prevProps, prevState) {
    // update state 
    if (this.props.url !== prevProps.url) {
      let url = this.props.url === 'top' ?  this.props.url : `topics/`+this.props.url
      // forceUpdate()
      window.scrollTo(0, 0);
      
      this.setState({ currentPage: 1 });
    this.setState({ loading: true });
      fetch("https://inshorts.vercel.app/news/" + url + "?offset=0&limit=" + this.props.limit)
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        this.setState({ loading: false });
        this.setState({ contacts: data.data.articles });
      })
      .catch(err => { 
        this.setState({errorMessage: err.message});
        // this.setState({loading: false});
      });
    this.listenToScrollEvent();
  }
    }
  
  componentDidMount() {
    let url = this.props.url === 'top' ?  this.props.url : `topics/`+this.props.url

    document.body.classList.toggle("landing-page");
    window.scrollTo(0, 0);
    this.setState({ loading: true });
    fetch("https://inshorts.vercel.app/news/" + url + "?offset=0&limit=" + this.props.limit)
      .then((res) => res.json())
      .then((data) => {
        console.log('called',data);
        this.setState({ loading: false });
        this.setState({ contacts: data.data.articles });
      })
      .catch(err => { 
        this.setState({errorMessage: err.message});
      });
    this.listenToScrollEvent();
  }
  componentWillUnmount() {
    document.body.classList.toggle("landing-page");
  }

  render() {

    const { currentPage, postsPerPage, contacts, loading, errorMessage } = this.state;

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = contacts.slice(indexOfFirstPost, indexOfLastPost);

    const paginate = pageNum => this.setState({ currentPage: pageNum });

    const nextPage = () => this.setState({ currentPage: currentPage + 1 });

    const prevPage = () => this.setState({ currentPage: currentPage - 1 });

    let card = [];
_.times(9, (i) => {
  card.push(
  <Col md="4" key={i}>
            <Row>
              <Col className="text-center" md="12">
                <div className="ph-item">
                  <div className="ph-col-12">
                    <div className="ph-row">
                      <div className="ph-col-6 big" />
                      <div className="ph-col-4 empty big" />
                      <div className="ph-col-2 big" />
                      <div className="ph-col-4" />
                      <div className="ph-col-8 empty" />
                      <div className="ph-col-6" />
                      <div className="ph-col-6 empty" />
                      <div className="ph-col-12" />
                    </div>{" "}
                    <div className="ph-picture" />
                  </div>
                </div>
              </Col>
            </Row>
            <Row></Row>
          </Col>

  
  );
});
    let newsfinal = (
      <div>
        <Row>
          <Col md="4">
            <hr className="line-primary" />
            <h1 className="mb-5">
              {this.props.name} <span className="text-primary">News</span>
            </h1>
          </Col>
        </Row>
        <Row>
          {loading ? card : null}
        </Row>
        <Row>
          {errorMessage ? null : currentPosts.map((contact) => (
            <Col md="4" key={contact.created_at}>
              <a href={contact.sourceUrl} target="blank" rel="noreferrer">
                <Card className="card-coin card-plain">
                  <CardBody>
                    <Row>
                      <Col className="text-center" md="12">
                        <h4 className="">{contact.title}</h4>

                        <hr className="line-primary" />
                      </Col>
                    </Row>
                    <Row>
                      <ListGroup>
                        <ListGroupItem className="text-left text-normal">
                          {contact.content}
                        </ListGroupItem>

                        <small className="text-muted">
                          -{contact.sourceName}
                        </small>
                      </ListGroup>
                    </Row>
                  </CardBody>
                </Card>
              </a>
            </Col>
          ))}
        </Row>
        {loading ? null : <CustomPagination
          postsPerPage={postsPerPage}
          totalPosts={contacts.length}
          paginate={paginate}
          nextPage={nextPage}
          prevPage={prevPage}
          currentPage={currentPage}
        />}
      </div>
    );

    return (
      <>
       <LoadingOverlay
      active={this.state.errorMessage}
      styles={{
        wrapper: {
            display: 'inherit',
            'flex-grow': 'inherit'
        }
    }}
      text={this.state.errorMessage ? 'Something went wrong. Error: '+this.state.errorMessage : 'Loading...' }
      >
        <IndexNavbar />
        
        <div className="wrapper">
        <Responsive displayIn={["Laptop"]}>
        <PageHeader />
  </Responsive>
        
        
          <section className="section section-lg section-coins">
            <img
              alt="..."
              className="path"
              src={require("assets/img/path3.png")}
            />
            <Container>
            
              {newsfinal}
              {/* { this.state.errorMessage &&
  <h4 className=" text-center"> { this.state.errorMessage } </h4> } */}
              
            </Container>
            
          </section>
          <div style={{zIndex:3,position:'sticky',height:4+'px',bottom:0}} className="progress-container progress-primary">
          
          <Progress max="100" value={this.state.scrollPostion} barClassName="progress-bar-info">
            
          </Progress>
        </div>
          <Footer />
          
        </div>
        </LoadingOverlay>
      </>
    );
  }
}

export default LandingPage;
