/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
import Responsive from "components/Responsive";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <Container>
          <Row>
            <Col md="3">
              <h1 className="title">TL•DR News</h1>
              <Responsive displayIn={["Laptop"]}>
        
  
              <a href="https://www.netlify.com" target="_blank" rel="noreferrer noopener" aria-label="Deploys by Netlify">
                  <img src="https://www.netlify.com/img/global/badges/netlify-color-accent.svg" alt="Deploys by Netlify"/>
                </a></Responsive>
            </Col>
             <Col md="3">
              <Nav>
                <NavItem>
                  <NavLink to="/" tag={Link}>
                    Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/business" tag={Link}>
                  Business
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/sports" tag={Link}>
                    Sports
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/technology" tag={Link}>
                    Technology
                  </NavLink>
                </NavItem>
              </Nav>
            </Col> 
            <Col md="3">
              <Nav>
                <NavItem>
                  <NavLink to="/politics" tag={Link}>
                    Politics
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/entertainment" tag={Link}>
                  Entertainment
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/science" tag={Link}>
                    Science
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/world" tag={Link}>
                    World
                  </NavLink>
                </NavItem>
              </Nav>
            </Col> 
           
            <Col md="3">
              <h4 className="title">Follow me</h4>
              
              <div className="btn-wrapper profile">
                <Button
                  className="btn-icon  "
                  color="success"
                  href="https://twitter.com/battleofplassey"
                  id="tooltip622135962"
                  target="_blank"
                >
                  <i className="fab fa-twitter" />
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip622135962">
                  Twitter
                </UncontrolledTooltip>
                <Button
                  className="btn-icon"
                  color="info"
                  href="https://www.facebook.com/palashshanu"
                  id="tooltip230450801"
                  target="_blank"
                >
                  <i className="fab fa-facebook-square" />
                </Button>
                <UncontrolledTooltip delay={1} target="tooltip230450801">
                  Facebook
                </UncontrolledTooltip>
                <Button
                  className="btn-icon"
                  color="primary"
                  href="https://instagram.com/palash_alphas"
                  id="tooltip318450378"
                  target="_blank"
                >
                  <i className="fab fa-instagram" />
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip318450378">
                  Instagram
                </UncontrolledTooltip>
                <br></br><br></br>
                {/* <p>Made with ❤️ in India</p> */}
                <Responsive displayIn={["Mobile"]}>
        
  
        <a href="https://www.netlify.com" target="_blank" rel="noreferrer noopener" aria-label="Deploys by Netlify">
            <img src="https://www.netlify.com/img/global/badges/netlify-color-accent.svg" alt="Deploys by Netlify"/>
          </a>
          </Responsive>
                {/* <a href="https://app.netlify.com/sites/tl-dr/deploys" target="_blank" rel="noreferrer noopener" aria-label="Deploys by Netlify">
                  <img src="https://api.netlify.com/api/v1/badges/ca558bac-faea-41d1-9dca-a544ec129c72/deploy-status" alt="Deploys by Netlify"/>
                </a> */}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

export default Footer;
