/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
  // Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  // Label,
  FormGroup,
  // UncontrolledAlert
} from "reactstrap";

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

  // const contextClass = {
  //   success: "bg-blue-600",
  //   error: "bg-red-600",
  //   info: "bg-gray-600",
  //   warning: "bg-orange-400",
  //   default: "bg-indigo-600",
  //   dark: "bg-pink-600 font-gray-300",
  // };

class ComponentsNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
      formModal: false,
      btncolor: "primary",
       email: "", message: "" ,
       alert : false
    };
  }

    

  

    /* Here’s the juicy bit for posting the form submission */
   
    handleSubmit = e => {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...this.state })
      })
        .then((er) => {toast.success('Your message has been received.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });})
        .catch(error => alert(error));
        this.toggleModal("formModal");
      e.preventDefault();
    };

    // ShowAlert = (e) =>(
    //   <div className="section section-notifications" id="notifications">
    //     <Container>
    //       <div className="space" />
    //         <UncontrolledAlert className="alert-with-icon" color="success">
    //         <span data-notify="icon" className="tim-icons icon-bell-55" />
    //         <span>
    //           <b>Well done! -</b>
    //           This is a regular notification made with ".alert-success"
    //         </span>
    //       </UncontrolledAlert>
          
    //     </Container>
    //   </div>
    // )

    handleChange = e => this.setState({ [e.target.name]: e.target.value });

  toggleModal = modalState => {
    this.setState({
      [modalState]: !this.state[modalState]
    });
  };

  
  componentDidMount() {
    window.addEventListener("scroll", this.changeColor);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.changeColor);
  }
  changeColor = () => {
    if (
      document.documentElement.scrollTop > 99 ||
      document.body.scrollTop > 99
    ) {
      this.setState({
        color: "bg-primary",
        btncolor: "info"
      });
    } else if (
      document.documentElement.scrollTop < 100 ||
      document.body.scrollTop < 100
    ) {
      this.setState({
        color: "navbar-transparent",
        btncolor: "primary"
      });
    }
  };
  toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };

  toggleCollapseMobile = () =>{
    document.documentElement.classList.toggle("nav-open");
    this.setState({
      collapseOpen: false
    });
  }
  onCollapseExiting = () => {
    this.setState({
      collapseOut: "collapsing-out"
    });
  };
  onCollapseExited = () => {
    this.setState({
      collapseOut: ""
    });
  };
  scrollToDownload = () => {
    document
      .getElementById("download-section")
      .scrollIntoView({ behavior: "smooth" });
  };
  render() {
    const { email, message } = this.state;
    return (
      <Navbar
        className={"fixed-top " + this.state.color}
        color-on-scroll="100"
        expand="lg"
      >
        <Container>
        <div>
        {/* <button >Notify!</button> */}
        <ToastContainer />
      </div>
          <div className="navbar-translate">
            <NavbarBrand
              to="/"
              tag={Link}
              id="navbar-brand"
            >
              <span>TL•DR </span>
              News
            </NavbarBrand>
            <UncontrolledTooltip placement="bottom" target="navbar-brand">
            Too Long ; Didn't Read
            </UncontrolledTooltip>
            <button
              aria-expanded={this.state.collapseOpen}
              className="navbar-toggler navbar-toggler"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse
            className={"justify-content-end " + this.state.collapseOut}
            navbar
            isOpen={this.state.collapseOpen}
            onExiting={this.onCollapseExiting}
            onExited={this.onCollapseExited}
          >
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                  TL•DR News
                  </a>
                </Col>
                <Col className="collapse-close text-right" xs="6">
                  <button
                    aria-expanded={this.state.collapseOpen}
                    className="navbar-toggler"
                    onClick={this.toggleCollapse}
                  >
                    <i className="tim-icons icon-simple-remove" />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav navbar>
              <NavItem className="p-0">
                <NavLink
                  data-placement="bottom"
                  href="https://twitter.com/battleofplassey"
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Follow us on Twitter"
                >
                  <i className="fab fa-twitter" />
                  <p className="d-lg-none d-xl-none">Twitter</p>
                </NavLink>
              </NavItem>
              <NavItem className="p-0">
                <NavLink
                  data-placement="bottom"
                  href="https://www.facebook.com/palashshanu"
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Add us on Facebook"
                >
                  <i className="fab fa-facebook-square" />
                  <p className="d-lg-none d-xl-none">Facebook</p>
                </NavLink>
              </NavItem>
              <NavItem className="p-0">
                <NavLink
                  data-placement="bottom"
                  href="https://www.instagram.com/palash_alphas"
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Follow us on Instagram"
                >
                  <i className="fab fa-instagram" />
                  <p className="d-lg-none d-xl-none">Instagram</p>
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  href="#pablo"
                  nav
                  onClick={e => e.preventDefault()}
                >
                  <i className="fa fa-home d-lg-none d-xl-none" />
                  Home
                </DropdownToggle>
                <DropdownMenu className="dropdown-with-icons">
                  <DropdownItem tag={Link} to="/" onClick={this.toggleCollapseMobile}>
                    <i className="tim-icons icon-paper" />
                    Latest News
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/business" onClick={this.toggleCollapseMobile}>
                    <i className="tim-icons icon-money-coins" />
                    Business News
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/sports" onClick={this.toggleCollapseMobile}>
                    <i className="tim-icons icon-user-run" />
                    Sports News
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/technology" onClick={this.toggleCollapseMobile}>
                    <i className="tim-icons icon-mobile" />
                    Technology News
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/politics" onClick={this.toggleCollapseMobile}>
                    <i className="tim-icons icon-bank" />
                    Politics News
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/entertainment" onClick={this.toggleCollapseMobile}>
                    <i className="tim-icons icon-video-66" />
                    Entertainment News
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/science" onClick={this.toggleCollapseMobile}>
                    <i className="tim-icons icon-atom" />
                    Science News
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/world" onClick={this.toggleCollapseMobile}>
                    <i className="tim-icons icon-world" />
                    World News
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
               <Button
                 className="nav-link d-none d-lg-block"
                //  color="default"
                 color={this.state.btncolor}
                 
                 target="_blank"
                 onClick={() => this.toggleModal("formModal")}
                //  href="https://www.creative-tim.com/product/blk-design-system-pro-react?ref=bdsr-user-archive-index-navbar-upgrade-pro"
               >
                 <i className="tim-icons icon-spaceship" /> Contact
               </Button>
             </NavItem>
              {/* <NavItem>
                <Button
                  className="nav-link d-none d-lg-block"
                  color="default"
                  onClick={this.scrollToDownload}
                >
                  <i className="tim-icons icon-cloud-download-93" /> Download
                </Button>
              </NavItem> */}
            </Nav>
          </Collapse>
        </Container>
        
        


        {/* Start Form Modal */}
        <Modal
              modalClassName="modal-black"
              isOpen={this.state.formModal}
              toggle={() => this.toggleModal("formModal")}
            >
              <div className="modal-header justify-content-center">
                <button
                  className="close"
                  onClick={() => this.toggleModal("formModal")}
                >
                  <i className="tim-icons icon-simple-remove text-white" />
                </button>
                <div className="text-muted text-center ml-auto mr-auto">
                  <h3 className="mb-0">Contact Us</h3>
                </div>
              </div>
              <div className="modal-body">
                <div className="btn-wrapper text-center">
                  {/* <Button
                    className="btn-primary btn-icon"
                    color="default"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  > <i className="tim-icons icon-email-85" />
                     <img alt="..." src={require("assets/img/github.svg")} /> 
                  </Button> */}
                  <Button
                    className="btn-primary btn-icon"
                    color="default"
                    href="mailto:contact@palashshrivastava.tech"
                    // onClick={e => e.preventDefault()}
                  > <i className="tim-icons icon-send" />
                    {/* <img alt="..." src={require("assets/img/google.svg")} /> */}
                  </Button>
                </div>
                <div className="text-center text-muted mb-4 mt-3">
                  <small>Or fill the form and we will get back</small>
                </div>
                {/* <Form role="form" name="submitMeme" action="/" netlify> */}
                  <form name="submitMeme" onSubmit={this.handleSubmit}>
                  <FormGroup className="mb-3">
                    <InputGroup
                      className={classnames("input-group-alternative", {
                        "input-group-focus": this.state.emailFocus
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Your Email"
                        type="email"
                        onFocus={e => this.setState({ emailFocus: true })}
                        onBlur={e => this.setState({ emailFocus: false })}
                        name="email"
                        value={email}
                        onChange={this.handleChange}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup
                      className={classnames("input-group-alternative", {
                        "input-group-focus": this.state.passwordFocus
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-chat-33" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Your Message"
                        type="text" rows={3}
                        onFocus={e => this.setState({ passwordFocus: true })}
                        onBlur={e => this.setState({ passwordFocus: false })}
                        name="message" value={message} onChange={this.handleChange} 
                        autoComplete="off"
                      />
                    </InputGroup>
                  </FormGroup>
                   {/*<FormGroup check className="mt-3">
                    <Label check>
                      <Input defaultChecked type="checkbox" />
                      <span className="form-check-sign" />
                      Remember me!
                    </Label> 
                  </FormGroup>*/}
                  <div className="text-center">
                    <Button className="my-4" color="primary" type="submit">
                      Send
                    </Button>
                  </div>
                  </form>
                {/* </Form> */}
              </div>
            </Modal>
            {/* End Form Modal */}
      </Navbar>
    );
  }
}

export default ComponentsNavbar;
