/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, 
  // Redirect 
} from "react-router-dom";

import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-react.scss?v=1.1.0";
import "assets/demo/demo.css";

import Business from "views/examples/business.js";
import Error from "views/examples/404.js";


ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route
        path="/business"
        render={props => <Business name={'Business'} url={'business'} limit={200} />}
      />
      <Route
        path="/sports"
        render={props => <Business name={'Sports'} url={'sports'} limit={200} />}
      />
      <Route
        path="/technology"
        render={props => <Business name={'Technology'} url={'technology'} limit={200} />}
      />
      <Route
        path="/politics"
        render={props => <Business name={'Politics'} url={'politics'} limit={200} />}
      />
      <Route
        path="/entertainment"
        render={props => <Business name={'Entertainment'} url={'entertainment'} limit={200} />}
      />
      <Route
        path="/science"
        render={props => <Business name={'Science'} url={'science'} limit={200} />}
      />
      <Route
        path="/world"
        render={props => <Business name={'World'} url={'world'} limit={200} />}
      />
      <Route
        path="/" exact
        render={props => <Business name={'Latest'} url={'top'} limit={300} />}
      />
      <Route path="*" exact component={Error}/>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
